import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: {
			title: 'Zero Sleep Studios'
		}
	},
	{
		path: '/aboutus',
		name: 'aboutus',
		// route level code-splitting
		// this generates a separate chunk (aboutus.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "aboutus" */ '../views/AboutUsView.vue'),
		meta: {
			title: 'About Us | Zero Sleep Studios'
		}
	},
	// {
	// 	path: '/games',
	// 	name: 'games',
	// 	component: () => import('../views/GamesView.vue')
	// },
	{
		path: '/voltagevendors',
		name: 'voltagevendors',
		component: () => import('../views/VoltageVendorsView.vue'),
		meta: {
			title: 'Voltage Vendors Game | Zero Sleep Studios'
		}
	},
	// {
	// 	path: '/blog',
	// 	name: 'blog',
	// 	component: () => import('../views/BlogView.vue')
	// },
	// {
	// 	path: '/blogpost',
	// 	name: 'blogpost',
	// 	component: () => import('../views/BlogPostView.vue')
	// },
	{
		path: '/formatting',
		name: 'formatting',
		component: () => import('../views/FormattingView.vue')
	},
	{
		path: '/qr',
		name: 'qr',
		component: () => import('../views/QRCodeView.vue'),
		meta: {
			title: 'Mailing List | Zero Sleep Studios'
		}
		// redirect: '/'
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router


// Resource used: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
	// `/nested`'s will be chosen.
	const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
	
	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
	
	const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
	
	// If a route with a title was found, set the document (page) title to that value.
	if(nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
	} else if(previousNearestWithMeta) {
		document.title = previousNearestWithMeta.meta.title;
	}
	
	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
	
	// Skip rendering meta tags if there are none.
	if(!nearestWithMeta) return next();
	
	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags.map(tagDef => {
		const tag = document.createElement('meta');
		
		Object.keys(tagDef).forEach(key => {
			tag.setAttribute(key, tagDef[key]);
		});
		
		// We use this to track which meta tags we create so we don't interfere with other ones.
		tag.setAttribute('data-vue-router-controlled', '');
		
		return tag;
	})
	// Add the meta tags to the document head.
	.forEach(tag => document.head.appendChild(tag));
	
	next();
});
