<template>
	<div class="jumbotron-banner">
		<div :style="{ backgroundImage: 'url('+imgURL+')' }" class="jumbotron jumbotron-transparent-cover jumbotron-fluid px-2 px-xs-5">
			<div class="container">
				<h1 class="jumbotron-text"><span><b>{{ headingMsg }}</b></span></h1>
				<h4 class="jumbotron-text top-margin"><span>{{ subheadingMsg }}</span></h4>
				<a v-if="linkURL" type="button" class="btn btn-primary text-center pt-2 pb-0 btn-margin" :href="linkURL">
					<h4 v-if="btnMsg"><b>{{ btnMsg }}</b></h4>
					<h4 v-else><b>FIND OUT MORE</b></h4>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'JumbotronBanner',
	props: {
		headingMsg: String,
		subheadingMsg: String,
		btnMsg: String,
		imgURL: String,
		linkURL: String
	}
}
</script>

<style scoped lang="scss">

</style>
