<template>
	<div class="navigation-bar">
		<nav class="navbar navbar-expand-md navbar-dark fixed-top">
			<div class="container-fluid">
				
				<a class="navbar-brand" href="/">
					<img src="@/assets/images/logo.png" height="80" class="d-inline-block align-top" alt="">
				</a>
				
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" id="navbar-toggler">
					<span class="navbar-toggler-icon"></span>
				</button>
				
				<div class="collapse navbar-collapse" id="navbarNav">
					<ul class="navbar-nav me-auto pt-2">
						<li class="nav-item">
							<router-link class="nav-link" to="/" @click="handleNavClick"><h6>Home</h6></router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/aboutus" @click="handleNavClick"><h6>About Us</h6></router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/voltagevendors" @click="handleNavClick"><h6>Voltage Vendors</h6></router-link>
						</li>
						<!-- <li class="nav-item">
							<router-link class="nav-link" to="/games">Games</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/blog">Blog</router-link>
						</li> -->
					</ul>
					
					<ul class="navbar-nav d-flex flex-row pt-2">
						<!-- <li class="nav-item me-3 me-lg-0">
							<a class="nav-link" href="#">
								<i class="fab fa-facebook-f text-white"></i>
							</a>
						</li> -->
						<li class="nav-item me-3 me-lg-0">
							<a class="nav-link" href="https://www.instagram.com/zerosleepstudios/?utm_source=ig_embed&amp;utm_campaign=loading" target="_blank">
								<h6 class="fab fa-instagram"></h6>
							</a>
						</li>
						<li class="nav-item me-3 me-lg-0">
							<a class="nav-link" href="https://twitter.com/ZeroSleepStudio" target="_blank">
								<h6 class="fab fa-twitter"></h6>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>

export default {
	name: 'NavigationBar',
	
	methods: {
		handleNavClick() {
			window.scrollTo(0,0);
			if (window.innerWidth < 768) 
				document.getElementById("navbar-toggler").click();
		}
	}
}


</script>

<style lang="scss" scoped>
</style>
