<template>
	<div class="jumbotron-banner-mailing-list">
		<div :style="{ backgroundImage: 'url('+imgURL+')' }" class="jumbotron jumbotron-transparent-cover jumbotron-fluid px-2 px-xs-5">
			<div class="container">
				<h1 class="jumbotron-text"><span><b>{{ headingMsg }}</b></span></h1>
				<h4 class="jumbotron-text top-margin"><span>{{ subheadingMsg }}</span></h4>
				
				<div id="mc_embed_shell">
					<div id="mc_embed_signup">
						<form action="https://gmail.us10.list-manage.com/subscribe/post?u=7c80c3feadb10162535eb340b&amp;id=d6ccafe716&amp;f_id=00f893e5f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self" novalidate="">
							<div class="indicates-required">
								<div class="mc-field-group"><label for="mce-EMAIL"></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value="" placeholder="Your email"></div>
								<div id="mce-responses" class="clear foot">
									<div class="response" id="mce-error-response" style="display: none;"></div>
									<div class="response" id="mce-success-response" style="display: none;"></div>
								</div>
								<div aria-hidden="true" style="position: absolute; left: -5000px;">
									/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
									<input type="text" name="b_7c80c3feadb10162535eb340b_d6ccafe716" tabindex="-1" value="">
								</div>
								<div class="optionalParent">
									<div class="clear foot">
										<button type="submit" name="subscribe" id="mc-embedded-subscribe" class="btn btn-primary text-center pt-2 pb-0 btn-margin" value="Subscribe"><h4><b>Subscribe</b></h4></button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				
				<a v-if="linkURL" type="button" class="btn btn-primary text-center pt-2 pb-0 btn-margin" :href="linkURL">
					<h4 v-if="btnMsg"><b>{{ btnMsg }}</b></h4>
					<h4 v-else><b>RETURN TO HOMEPAGE</b></h4>
				</a>
				
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'JumbotronBannerMailingList',
	props: {
		headingMsg: String,
		subheadingMsg: String,
		btnMsg: String,
		imgURL: String,
		linkURL: String
	}
}
</script>

<style scoped lang="scss">

</style>
